import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
// import AlpineFocus from '@alpinejs/focus'
// import AlpineIntersect from '@alpinejs/intersect'

import Swup from 'swup'
import BodyClassPlugin from '@swup/body-class-plugin'
import HeadPlugin from '@swup/head-plugin'
import ScrollPlugin from '@swup/scroll-plugin'

import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

// Alpine
window.Alpine = Alpine
Alpine.plugin(AlpineCollapse)
// Alpine.plugin(AlpineFocus)
// Alpine.plugin(AlpineIntersect)

Alpine.data('MobileHero', () => ({
  glide: null,
  init() {
    this.glide = new Glide(document.querySelector('[data-glide="MobileHero"]'), {
      animationDuration: 500,
      gap: 0,
    })
    this.glide.mount({ Controls, Breakpoints })
  },
  destroy() {
    this.glide.destroy()
  }
}))

Alpine.data('Hero', () => ({
  activeSlide: null,
  glider: null,
  texts: null,
  init() {
    this.texts = document.querySelectorAll(['data-glider-texts'])
    this.glider = new Glide(document.querySelector('[data-glide="hero"]'), {
      animationDuration: 500,
    })
    this.glider.on('run', () => {
      this.activeSlide = this.glider.index
    })
    this.glider.mount({ Controls, Breakpoints })
    this.activeSlide = this.glider.index
  },
  destroy() {
    this.glider.destroy()
  }
}))

Alpine.data('Video', () => ({
  isPlaying: false,
  init() {
  },
  togglePlay() {
    return this.isPlaying ? this.pause() : this.play()
  },
  play() {
    this.isPlaying = true
    this.$refs.video.play()
  },
  pause() {
    this.isPlaying = false
    this.$refs.video.pause()
  },
}))

Alpine.start()

// Swup
const swup = new Swup({
  containers: ['#swup', '#swup-page-header', '#swup-page-footer', '#swup-mobile-menu'],
  animationSelector: '[class*="swup-transition-"]',
  plugins: [
    new BodyClassPlugin(),
    new HeadPlugin(),
    new ScrollPlugin({
      doScrollingRightAway: true,
      animateScroll: false,
    }),
  ]
})

swup.hooks.on('content:replace', () => {
  // Add scripts that should run after a page content has replaced
  // ...

  // Re-initialize event listeners
  tearDownEventListeners()
  setupEventListeners()
  initializeGliders()

  // Always scroll to top
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
})


// Event listeners

/**
 * Setup all event listeners here.
 * This function will be called once, when this script is executed
 * and again on every page load handled by Swup.
 */
function setupEventListeners() {
  console.log('setup event listeners')
  // ...
}

let pageSliders = []
// let heroSlider = null

function initializeGliders() {
  document.querySelectorAll('[data-glide="page-slider"]').forEach(($el) => {
    const glider = new Glide($el, {
      perView: 3,
      gap:     32,
      rewind:  false,

      breakpoints: {
        1024: { perView: 2 },
        768:  { perView: 1 },
      },
    })
    glider.mount({ Controls, Breakpoints })
    pageSliders.push(glider)
  })

//   const heroSliderTexts = document.querySelectorAll('[data-glider-text]')
//
//   heroSlider = new Glide(document.querySelector('[data-glide="hero"]'))
//   heroSlider.mount({ Controls, Breakpoints })
//   heroSlider.on('run', function (move) {
//     heroSliderTexts.forEach((text) => {
//
//       console.log(text.dataset, parseInt(text.dataset.gliderText), heroSlider.index)
//       if (parseInt(text.dataset.gliderText) === heroSlider.index) {
//         text.classList.remove('hidden')
//       } else {
//         text.classList.add('hidden')
//       }
//     })
//   })
}

/**
 * Tear down all event listeners here.
 * This function will be called once, when this script is executed
 * and again on every page load handled by Swup.
 */
function tearDownEventListeners() {
  console.log('tear down event listeners')
  // ...
}

// Call function once to initialize event listeners
setupEventListeners()
initializeGliders()
